import { createStore } from "redux";
import { stringify } from "flatted";

const initialState = {
  student_type: "",
  programsState: {
    loading: true,
    programs: [],
  },
  profile_menu_right: "-400px",
  menu_type: "normal",
  divMainClass: "responsive",
  active_menu: "dashboard",
  tech_schooling__active_menu: "dashboard",
  active_profile_menu: "",
  respSideMenuClass: "",
  respSearch: false,
  isSignupUser: false,
  is_bookmarked: "",
  is_profile_update: false,
  is_trialSuccess: false,
  isProfessionCompleted: false,
  nextDesignationData: {},
  user_data: {},
  userSubscriptionType: "",
  day_by_day: [],
  campus_data: {},
  device_type: "",
  signup_data: {},
  isUserDataLoading: false,
  user_profile: {
    subscription_data: {},
    program: {},
    device_type: "",
  },
  rm_session: {},
  designation: {},
  nextPath: "/feed/",

  notifications: [],
  notifications_count: 0,

  message: {},
  isStudentUploadModal: false,
  isInvitationModal: false,
  is_validationModal: false,
  // Payment Link
  isPaymentLinkModal: false,
  payment_plan: {},
  isProfileModel: false,
  isLearnDiv: false,
  profileModalType: "",
  activeProgramNav: "",
  // My Profile
  selectedEditingMyProfileData: {},
  newUpdatesModalType: "",
  isNewUpdateModal: false,

  error: "",
  errorState: {
    isError: false,
    errorData: {
      status: 200,
      redirectLink: "",
      errorMessage: "An error occured, please try later",
    },
  },
  isTechDegreeForm: false,
  tidioSettings: {
    isOpened: false,
  },
  // popupSettings: {
  //     isViewed: false,
  // },
  prime_subscription: {},
  current_day_id: "",
  current_day_number: "",
  sat_user_data: {
    name: "",
    country: "",
    mobile: "",
    district: "",
    district_id: "",
    phoneCode: "",
    studentType: "",
    studentClass: "",
    campus_code: "",
    campus_id: "",
    photo: "",
    dob: "",
    semester: "",
    stream: "",
  },
  isMarked: false,
  isMalayalam: true,
  isNewNotification: false,
  school_scientist_data: {
    is_verified: false,
    name: "",
    phone: "",
    access_token: "",
    refresh_token: "",
    exam_status: "pending",
    start_timestamp: "",
    end_timestamp: "",
    is_loading: true,
  },
  scholarshipModalType: "phone",
  scholarshipExamType: "",
  scholarshipExamLanguage: "english",
  one_creator_data: {},
  msf_scholarship_data: {},
  one_creator_exam_data: {},
  msf_scholarship_exam_data: {},
  greenovation_scholarship_exam_data: {},
  trailer_status: false,
  tech_topic: "",
  school_scientist_members: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PROGRAMS":
      return {
        ...state,
        programsState: {
          loading: action.loading,
          programs: action.programs,
        },
      };
    case "CHANGE_LANGUAGE":
      return {
        ...state,
        isMalayalam: action.isMalayalam,
      };
    case "TOGGLE_PROFILE_MODAL":
      return {
        ...state,
        isProfileModel: !state.isProfileModel,
      };
    case "TOGGLE_NEW_UPDATES_MODAL":
      return {
        ...state,
        isNewUpdateModal: !state.isNewUpdateModal,
      };
    case "TOGGLE_LEARN_MODAL":
      return {
        ...state,
        isLearnDiv: action.isLearnDiv,
      };

    case "UPDATE_PROFILE_MODAL":
      return {
        ...state,
        profileModalType: action.profileModalType,
      };
    case "UPDATE_NEW_UPDATES_MODAL_TYPE":
      return {
        ...state,
        newUpdatesModalType: action.newUpdatesModalType,
      };
    case "UPDATE_MY_PROFILE_EDITING_DATA":
      return {
        ...state,
        selectedEditingMyProfileData: action.selectedEditingMyProfileData,
      };
    case "UPDATE_CURRENT_DAY":
      return {
        ...state,
        current_day_id: action.current_day_id,
        current_day_number: action.current_day_number,
      };
    case "UPDATE_ACTIVE_PROGRAM_NAV":
      return {
        ...state,
        activeProgramNav: action.activeProgramNav,
      };
    case "UPDATE_PRIME_PROGRAM_PLAN":
      return {
        ...state,
        primeSubscriptionPlan: action.primeSubscriptionPlan,
      };

    case "UPDATE_PRIME_SUBSCRIPTION":
      return {
        ...state,
        prime_subscription: action.primeSubscription,
      };
    case "UPDATE_SAT_USER_DATA":
      localStorage.setItem(
        "district",
        JSON.stringify(action.sat_user_data.district)
      );
      localStorage.setItem(
        "district_id",
        JSON.stringify(action.sat_user_data.district_id)
      );
      return {
        ...state,
        sat_user_data: action.sat_user_data,
      };
    case "UPDATE_SCHOOL_SCIENTIST_DATA":
      const school_scientist_data = {
        ...state.school_scientist_data,
        ...action.school_scientist_data,
      };
      localStorage.setItem(
        "school_scientist_data",
        JSON.stringify(school_scientist_data)
      );
      return {
        ...state,
        school_scientist_data: school_scientist_data,
      };
    case "UPDATE_ONE_CREATOR_DATA":
      const one_creator_data = {
        ...state.one_creator_data,
        ...action.one_creator_data,
      };
      localStorage.setItem(
        "one_creator_data",
        JSON.stringify(one_creator_data)
      );
      return {
        ...state,
        one_creator_data: one_creator_data,
      };
    case "UPDATE_SCHOLARSHIP_LANGUAGE":
      const scholarshipExamLanguage = action.scholarshipExamLanguage;
      localStorage.setItem(
        "scholarshipExamLanguage",
        JSON.stringify(scholarshipExamLanguage)
      );
      return {
        ...state,
        scholarshipExamLanguage: scholarshipExamLanguage,
      };
    case "UPDATE_MSF_SCHOLARSHIP_DATA":
      const msf_scholarship_data = {
        ...state.msf_scholarship_data,
        ...action.msf_scholarship_data,
      };
      localStorage.setItem(
        "msf_scholarship_data",
        JSON.stringify(msf_scholarship_data)
      );
      return {
        ...state,
        msf_scholarship_data: msf_scholarship_data,
      };
    case "UPDATE_ONE_CREATOR_EXAM_DATA":
      const one_creator_exam_data = {
        ...state.one_creator_exam_data,
        ...action.dispatchValue,
      };
      localStorage.setItem(
        "one_creator_exam_data",
        JSON.stringify(one_creator_exam_data)
      );
      return {
        ...state,
        one_creator_exam_data: one_creator_exam_data,
      };
    case "UPDATE_MSF_SCHOLARSHIP_EXAM_DATA":
      const msf_scholarship_exam_data = {
        ...state.msf_scholarship_exam_data,
        ...action.dispatchValue,
      };
      localStorage.setItem(
        "msf_scholarship_exam_data",
        JSON.stringify(msf_scholarship_exam_data)
      );
      return {
        ...state,
        msf_scholarship_exam_data: msf_scholarship_exam_data,
      };
    case "UPDATE_GREENOVATION_SCHOLARSHIP_EXAM_DATA":
      const greenovation_scholarship_exam_data = {
        ...state.greenovation_scholarship_exam_data,
        ...action.dispatchValue,
      };
      localStorage.setItem(
        "greenovation_scholarship_exam_data",
        JSON.stringify(greenovation_scholarship_exam_data)
      );
      return {
        ...state,
        greenovation_scholarship_exam_data: greenovation_scholarship_exam_data,
      };
    case "UPDATE_SCHOLARSHIP_MODAL_TYPE":
      return {
        ...state,
        scholarshipModalType: action.scholarshipModalType,
      };
    case "UPDATE_SCHOLARSHIP_EXAM_TYPE":
      return {
        ...state,
        scholarshipExamType: action.dispatchValue,
      };
    case "UPDATE_CHAT_PROFILE":
      return {
        ...state,
        chat_profile: action.chat_profile,
      };
    case "UPDATE_STUDENT_TYPE":
      return {
        ...state,
        student_type: action.student_type,
      };

    case "UPDATE_DAY_BY_DAY":
      const day_by_day_data = {
        ...state.day_by_day,
        ...action.day_by_day,
      };

      return {
        ...state,
        day_by_day: day_by_day_data,
      };

    case "UPDATE_ERROR":
      //  default state of error status
      let isError = action.isError ? action.isError : true;
      let status = 400;
      // if the there is a error message from components then the error message is show else default error message is shown
      let errorMessage = action.errorMessage
        ? action.errorMessage
        : "An error occured, please try later";
      // used to update the redirect link in case of redirect link available
      let redirectLink = action.redirectLink ? action.redirectLink : "";

      // if there is a responce in error state
      if (action.error.response) {
        status = action.error.response.status;
      }
      // updates the store
      return {
        ...state,
        errorState: {
          isError: true,
          errorData: {
            status: status,
            errorMessage: errorMessage,
            redirectLink: redirectLink,
          },
        },
      };
    case "RESET_ERROR":
      return {
        ...state,
        errorState: {
          isError: false,
          errorData: {},
        },
      };
    case "TOGGLE_VALIDATION_MODAL":
      return {
        ...state,
        is_validationModal: !state.is_validationModal,
      };
    case "TOGGLE_TECH_DEGREE_FORM_MODAL":
      return {
        ...state,
        isTechDegreeForm: !state.isTechDegreeForm,
      };

    case "TOGGLE_PAYMENT_LINK_MODAL":
      return {
        ...state,
        isPaymentLinkModal: !state.isPaymentLinkModal,
      };

    case "UPDATE_PAYMENT_LINK_MODAL":
      return {
        ...state,
        payment_plan: action.payment_plan,
      };
    case "UPDATE_SUBSCRIPTION_TYPE":
      return {
        ...state,
        userSubscriptionType: action.userSubscriptionType,
      };
    case "TOGGLE_PROFILE_MENU":
      if (state.profile_menu_right === 0) {
        return {
          ...state,
          profile_menu_right: "-400px",
        };
      } else {
        return {
          ...state,
          profile_menu_right: 0,
        };
      }
    case "TOGGLE_STUDENT_UPLOAD_MODAL":
      return {
        ...state,
        isStudentUploadModal: !state.isStudentUploadModal,
      };
    case "TOGGLE_IS_PROFILE_UPDATE":
      return {
        ...state,
        is_profile_update: action.is_profile_update,
      };
    case "TOGGLE_IS_TEIALSUCCESS_UPDATE":
      return {
        ...state,
        is_trialSuccess: action.is_trialSuccess,
      };

    case "TOGGLE_INVITATION_MODAL":
      return {
        ...state,
        isInvitationModal: !state.isInvitationModal,
      };
    case "TOGGLE_SIGNUP_USER":
      return {
        ...state,
        isSignupUser: action.bool,
      };
    case "UPDATE_PROFESSION_STATUS":
      return {
        ...state,
        isProfessionCompleted: action.bool,
      };
    case "TOGGLE_BOOKMARK_UPDATE":
      return {
        ...state,
        is_bookmarked: action.is_bookmarked,
      };
    case "MENU_TYPE":
      if (action.menu_type === "normal") {
        return {
          ...state,
          menu_type: action.menu_type,
          divMainClass: "",
        };
      } else if (action.menu_type === "hidden") {
        return {
          ...state,
          menu_type: action.menu_type,
          divMainClass: "responsive-mini",
        };
      } else {
        return {
          ...state,
          menu_type: action.menu_type,
          divMainClass: "responsive",
        };
      }

    case "TOGGLE_RESP_SIDE_MENU":
      if (action.value === "hide") {
        return {
          ...state,
          respSideMenuClass: "",
        };
      }
      if (state.respSideMenuClass === "") {
        return {
          ...state,
          respSideMenuClass: "show",
        };
      } else {
        return {
          ...state,
          respSideMenuClass: "",
        };
      }

    case "SET_MENU_ICON_REF":
      return {
        ...state,
        menuIconRef: action.ref,
      };

    case "ACTIVE_MENU":
      return {
        ...state,
        active_menu: action.active_menu,
      };

    case "TECH_SCHOOL_ACTIVE_MENU":
      return {
        ...state,
        tech_schooling__active_menu: action.tech_schooling__active_menu,
      };

    case "ACTIVE_PROFILE_MENU":
      return {
        ...state,
        active_profile_menu: action.active_profile_menu,
      };

    case "UPDATE_DESIGNATION":
      return {
        ...state,
        designation: action.designation,
      };

    case "UPDATE_NEXT_DESIGNATION":
      return {
        ...state,
        nextDesignationData: action.nextDesignationData,
      };

    case "UPDATE_PRACTICE_FILTER":
      return {
        ...state,
        practiceFilter: action.practiceFilter,
      };

    case "UPDATE_LESSONS":
      return {
        ...state,
        lessons: action.lessons,
      };
    case "TOGGLE_LESSONS_LOADING": {
      return {
        ...state,
        isLessonsLoading: !state.isLessonsLoading,
      };
    }
    case "TOGGLE_TOPICS_LOADING": {
      return {
        ...state,
        isTopicsLoading: !state.isTopicsLoading,
      };
    }

    case "UPDATE_TOPICS":
      return {
        ...state,
        topics: action.topics,
      };

    case "UPDATE_TECH_CATEGORY":
      localStorage.setItem("tech_topic", JSON.stringify(action.tech_topic));
      return {
        ...state,
        tech_topic: action.tech_topic,
      };

    //Update freeSkills, freeDesignations, freeLessons, freeTopics
    case "UPDATE_FREE_DESIGNATION":
      return {
        ...state,
        freeDesignation: action.freeDesignation,
      };
    case "TOGGLE_FREE_DESIGNATIONS_LOADING":
      return {
        ...state,
        isFreeDesignationsLoading: !state.isFreeDesignationsLoading,
      };

    case "UPDATE_FREE_SKILLS":
      return {
        ...state,
        freeSkills: action.freeSkills,
      };
    case "UPDATE_FREE_LESSONS":
      return {
        ...state,
        freeLessons: action.freeLessons,
      };

    case "UPDATE_SUBSCRIPTION_DISCOUNT_STATUS": {
      return {
        ...state,
        subscriptionDiscountStatus: action.subscriptionDiscountStatus,
      };
    }
    case "UPDATE_USER_DATA":
      const user_data = { ...state.user_data, ...action.user_data };
      localStorage.setItem("user_data", JSON.stringify(user_data));
      return {
        ...state,
        user_data: user_data,
      };

    case "UPDATE_TIDIO_SETTINGS":
      const tidioSettings = {
        ...state.tidioSettings,
        ...action.tidioSettings,
      };
      localStorage.setItem("tidioSettings", JSON.stringify(tidioSettings));

      return {
        ...state,
        tidioSettings: action.tidioSettings,
      };

    // case "UPDATE_VACATION_POPUP_SETTINGS":
    //     const popupSettings = {
    //         ...state.popupSettings,
    //         ...action.popupSettings,
    //     };
    //     localStorage.setItem(
    //         "popupSettings",
    //         JSON.stringify(popupSettings)
    //     );
    //     return {
    //         ...state,
    //         popupSettings: action.popupSettings,
    //     };

    case "TOGGLE_FREE_SKILLS_LOADING":
      return {
        ...state,
        isFreeSkillsLoading: !state.isFreeSkillsLoading,
      };

    case "UPDATE_FREE_LESSONS":
      return {
        ...state,
        freeLessons: action.freeLessons,
      };
    //Challenge page
    // case "UPDATE_CHALLENGES":
    //     return {
    //         ...state,
    //         challenges: action.challenges,
    //     };
    case "UPDATE_SUBSCRIPTION_DISCOUNT_STATUS": {
      return {
        ...state,
        subscriptionDiscountStatus: action.subscriptionDiscountStatus,
      };
    }

    case "UPDATE_CAMPUS_DATA":
      localStorage.setItem("campus_data", JSON.stringify(action.campus_data));
      return {
        ...state,
        campus_data: action.campus_data,
      };

    case "UPDATE_SIGNUP_DATA":
      localStorage.setItem("signup_data", JSON.stringify(action.signup_data));
      return {
        ...state,
        signup_data: action.signup_data,
      };

    case "UPDATE_USER_PROFILE":
      return {
        ...state,
        user_profile: action.user_profile,
      };
    case "TOGGLE_PROFILE_LOADING":
      return {
        ...state,
        isUserDataLoading: !state.isUserDataLoading,
      };
    case "UPDATE_NEXT_PATH":
      return {
        ...state,
        nextPath: action.nextPath,
      };

    case "TOGGLE_RESP_SEARCH":
      if (action.respSearch) {
        return {
          ...state,
          respSearch: action.respSearch,
        };
      }

      return {
        ...state,
        respSearch: !state.respSearch,
      };

    case "UPDATE_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.notifications,
        notifications_count: action.notifications_count,
      };
    case "UPDATE_NOTIFICATIONS_MARKED":
      return {
        ...state,
        isMarked: action.isMarked,
      };

    // Update RM session
    case "UPDATE_RM_SESSION":
      return {
        ...state,
        rm_session: action.rm_session,
      };
    case "SET_MESSAGES":
      return {
        ...state,
        message: action.message,
      };
    case "ADD_MESSAGE":
      let message = state.message;
      if (action.message?.message_type === "start_typing") {
        if (action.message?.contact !== state.rm_session.chat_profile) {
          message.last_message = "typing...";
        }
      } else if (action.message?.message_type === "start_recording") {
        if (action.message?.contact !== state.rm_session.chat_profile) {
          message.last_message = "recording audio...";
        }
      } else if (action.message?.message_type === "read_message") {
        if (action.message?.contact === state.rm_session.chat_profile) {
          message.unread_messages_count = 0;
        }
      } else {
        message.last_message = action.message?.last_message;
        if (action.message?.message_type === "session_update") {
          message.unread_messages_count =
            action.message?.student_unread_messages_count;
        }
      }
      const newState = {
        ...state,
        message: message,
      };
      return newState;

    case "UPDATE_SS_MEMBERS":
      const data = [
        ...state.school_scientist_members,
        action.school_scientist_members,
      ];
      localStorage.setItem("school_scientist_members", JSON.stringify(data));
      return {
        ...state,
        school_scientist_members: data,
      };
    case "CLEAR_SS_MEMBERS":
      localStorage.removeItem("school_scientist_members");
      return {
        ...state,
        school_scientist_members: [],
      };

    default:
      return state;
  }
};

const store = createStore(reducer);
export default store;
